<template>
  <v-navigation-drawer
    id="app-drawer"
    app
    dark
    :src="image"
    class="fill-height"
  >
    <v-list class="v-select-list">
      <v-list-item>
        <v-img
          :src="logo"
          width="200px"
          contain
          aspect-ratio="3.8"
        />
      </v-list-item>
      <v-divider />
      <v-list-item
        v-for="([icon, text, link], i) in filteredItems"
        :key="i"
        link
        :class="link === $route.path ? 'highlighted' : ''"
        :to="link"
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $tc(text,2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapMutations,
} from 'vuex'

export default {
  data: () => ({
    items: [
      ['mdi-brain', 'Product.menu', '/products/list', 'products:read'],
      ['mdi-palette-swatch-outline', 'Product.productType', '/products/types', 'product-types:read'],
      ['mdi-swap-horizontal', 'Transaction.menu', '/assetManagerTransaction/list', 'asset-manager-transactions:read'],
      ['mdi-account-multiple', 'AssetManagers.menu', '/assetManagers', 'assetManagers:read'],
      ['mdi-account-details', 'Roles.menu', '/roles', 'roles:read'],
      ['mdi-email', 'Emails.menu', '/emails', 'emails:read'],
      ['mdi-comment-account', 'Common.businessIntroducer', '/business-introducers', 'business-introducers:read'],
      ['mdi-domain', 'Issuers.menu', '/issuers', 'issuers:read'],
      ['mdi-map-marker', 'Common.distributionLocation', '/distribution-locations', 'distribution-locations:read'],
      ['mdi-bank-transfer-in', 'Brokers.menu', '/brokers', 'brokers:read'],
      ['mdi-bank', 'Custodians.menu', '/custodians', 'custodians:read'],
      ['mdi-view-list', 'Stocks.menu', '/stocks', 'stocks:read'],
      ['mdi-email', 'Templates.menu', '/templates', 'templates:read'],
      ['mdi-file-tree', 'Common.atlanticEntities', '/atlantic-entities', 'atlantic-entities:read'],
      ['mdi-text-box-multiple', 'TemplateProduct.menu', '/templateProducts'],
      ['mdi-file-table-box-multiple', 'InvoiceTemplates.menu', '/invoice', 'invoice-templates:read'],
      ['mdi-calendar-multiselect', 'Observations', '/observations', 'product-schedule-dates:read'],
      ['mdi-cash-sync', 'Common.price', '/prices', undefined],
      ['mdi-medical-bag', 'Common.diagnostic', '/diagnostics', 'diagnostics:read'],
      ['mdi-format-list-bulleted-type', 'Common.reportFields', '/report-fields', 'report-fields:read'],
      ['mdi-cash-100', 'Common.pricing', '/pricing', 'pricings:read'],
      ['mdi-tune', 'Common.corporateActions', '/corporate-actions', 'stocks:read'],
      ['mdi-security-network', 'Regulators', '/regulators', 'regulators:read'],
      ['mdi-lightbulb', 'Product.productType', '/products/types', 'products:update'],
    ],
    logo: '/img/logo.png',
    image: '/img/background-drawer.jpg'
  }),
  computed: {
    filteredItems() {
      // Removes pages when the user does not have the required right
      return this.items?.filter(
        ([, , , right]) => !right || this.$store.getters['user/hasRight'](right)
      )
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('theme', ['setDrawer', 'toggleDrawer']),
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>

<style lang="scss">
    #app-drawer {
        .v-list__tile {
            border-radius: 4px;

            &--buy {
                margin-top: auto;
                margin-bottom: 17px;
            }
        }

        .v-image__image--contain {
            top: 9px;
            height: 60%;
        }

        .search-input {
            margin-bottom: 30px !important;
            padding-left: 15px;
            padding-right: 15px;
        }

        .v-list {
            background: rgba(23,44,83,.6);
        }
    }
</style>
